// import { Image } from "@chakra-ui/react";
import {
  CustomButton,
  StyledHeading,
  StyledText,
} from "../../../common/styles/Common.styled";
import {
  LeftSideContent,
  MyImage,
  NewsContentWrapper,
  NewsWrapper,
  RightSideContant,
} from "./News.styled";
import fancyFoodNyc from "../../../images/News/fancyFoodNyc.webp";
import newsPic from "../../../images/News/newsPic1.webp";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const News = () => {
  const [t] = useTranslation();

  return (
    <NewsWrapper className="NewsWrapper" id="News">
      <StyledHeading style={{ display: "flex", alignSelf: "center" }}>
        {t("TK_TTL_HOME_NEWS")}
      </StyledHeading>
      <Carousel
        autoPlay
        interval="4000"
        infiniteLoop
        showIndicators="false"
        showStatus="false"
        emulateTouch
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={20}
        className="news-page-caro prevent-select"
      >
        <NewsContentWrapper className="NewsContentWrapper">
          <LeftSideContent className="LeftSideContent">
            <StyledText>{parse(t("TK_DESC_HOME_NEWS_1"))}</StyledText>
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:7079939246958026752/"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <CustomButton variant="outline">
                {t("TK_BTN_HOME_NEWS")}
              </CustomButton>
            </a>
          </LeftSideContent>
          <RightSideContant className="RightSideContant">
            <MyImage
              className="fancyFood"
              src={fancyFoodNyc}
              alt="Fancy Food NYX"
            />
          </RightSideContant>
        </NewsContentWrapper>

        <NewsContentWrapper className="NewsContentWrapper">
          <LeftSideContent className="LeftSideContent">
            <StyledText>{parse(t("TK_DESC_HOME_NEWS_2"))}</StyledText>
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:7057457149907546113/"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <CustomButton variant="outline">
                {t("TK_BTN_HOME_NEWS")}
              </CustomButton>
            </a>
          </LeftSideContent>
          <RightSideContant className="RightSideContant">
            <MyImage
              className="newsPic"
              src={newsPic}
              alt="Elixis's team with Martin Riese"
            />
          </RightSideContant>
        </NewsContentWrapper>
      </Carousel>
    </NewsWrapper>
  );
};
