import { useNavigate } from "react-router-dom";
import { ContactUsTitle } from "../utils/mockData";
import { useContext, useEffect } from "react";
import {
  ChildMainWrapper,
  StyledAiOutlineArrowLeft,
  StyledHeading,
} from "../common/styles/Common.styled";
import { useMediaQuery } from "@chakra-ui/react";
import {
  ContactInfoWrapper,
  ContactLeftSideWrapper,
  ContactMainWrapper,
  ContactRightSideWrapper,
  ContactWrapper,
} from "../components/contact/ContactPage.styled";
import Socials from "../components/socials/Socials.jsx";
import { ContactForm } from "../components/contact/contactForm/contactForm";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import { useTranslation } from "react-i18next";

export const ContactPage = () => {
  const [t] = useTranslation();

  useEffect(() => {
    document.title = "Elixis";
  });

  const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <ContactMainWrapper
      className="MainWrapper"
      id="contact-us"
      style={{ top: `${isSmallerThan1000 ? "80px" : "100px"}` }}
      isOpen={showSidebar}
    >
      <ChildMainWrapper>
        <StyledHeading>
          <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
          {ContactUsTitle.title}
        </StyledHeading>
        <ContactWrapper className="ContactWrapper">
          <ContactLeftSideWrapper className="ContactLeftSideWrapper">
            <ContactInfoWrapper className="ContactInfoWrapper">
              <li>
                <span>{t("TL_SUBTTL_PHONE")}</span>
                <br />
                <a href="tel: +41 79 643 64 47">+41 79 643 64 47</a>
              </li>
              <li>
                <span>{t("TL_SUBTTL_EMAIL")}</span>
                <br />
                <a href="mailto:info@elixisswiss.ch">info@elixisswiss.ch</a>
              </li>
              <li>
                <span>{t("TL_SUBTTL_ADDRESS")}</span>
                <br />
                Gantrischstrasse 39, 3600 Thun
              </li>
              <li>{t("TL_PARA_WORKHRS")}</li>
            </ContactInfoWrapper>
            <Socials />
          </ContactLeftSideWrapper>
          <ContactRightSideWrapper className="ContactRightSideWrapper">
            <ContactForm />
          </ContactRightSideWrapper>
        </ContactWrapper>
      </ChildMainWrapper>
    </ContactMainWrapper>
  );
};
