import {
  MiniLogo,
  MiniText,
  NewsletterWrapper,
  Subtitle,
  Title,
} from "./Newsletter.styled";
import logoShort from "../../images/Logos/logoShortA.svg";
import SubscriptionForm from "./SubscriptionForm";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const [t] = useTranslation();

  return (
    <NewsletterWrapper className="NewsletterWrapper">
      <MiniLogo src={logoShort}></MiniLogo>
      <Title>{t("TK_TTL_NEWLTTR")}</Title>
      <Subtitle>{t("TK_SUBTTL_NEWLTTR")}</Subtitle>
      <SubscriptionForm className="SubscriptionForm" />
      <MiniText>{t("TK_PARA_STAYUPTODATE")}</MiniText>
    </NewsletterWrapper>
  );
};
export default Newsletter;
