import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { ContactPage } from "./pages/contact-page";
import { PrivacyPolicyPage } from "./pages/privacy-page";
import { AboutUsPage } from "./pages/about-us";
import { AquaPage } from "./pages/aqua-page";

export const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/aqua-page" element={<AquaPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </AnimatePresence>
  );
};
