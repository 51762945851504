import { Select } from "@chakra-ui/react";
import styled from "styled-components";

export const StyledSelectWrapper = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    width: 15%;
  }
  @media (max-width: 750px) {
    width: 25%;
  }
  @media (max-width: 500px) {
    width: 35%;
  }
`;

export const StyledSelect = styled(Select)`
  /* width: 100%; */
`;

export const StyledOption = styled.option`
  background-color: #3b3b3b !important;
  color: white !important;
`;
