import { HomePageWrapper } from "../components/home/HomePage.styled";
import { HomeSlider } from "../components/home/homeSlider/HomeSlider";
import { AquaProducts } from "../components/home/products/AquaProducts/AquaProducts";
import { Brochure } from "../components/home/brochure/Brochure";
import { News } from "../components/home/news/News";
import { useContext, useEffect, useState } from "react";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import ScrollToHashElement from "../utils/scrollToHashElement";
import { useLocation } from "react-router-dom";
// import { useMediaQuery } from "@chakra-ui/react";

export const HomePage = () => {
  // const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);
  const location = useLocation();
  const [id, setId] = useState(null);

  useEffect(() => {
    const hashedStr = location.hash.slice(1);
    if (hashedStr) {
      setId(hashedStr);
    } else {
      setId(null);
    }
  }, [location.hash]);

  return (
    <HomePageWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      className="HomePageWrapper"
      isOpen={showSidebar}
      // style={{ top: `${isSmallerThan1000 ? "77px" : "99px"}` }}
    >
      <ScrollToHashElement id={id} />
      <HomeSlider className="HomeSlider" />
      <AquaProducts className="AquaProducts" />
      <Brochure className="Brochure" />
      <News className="News" />
    </HomePageWrapper>
  );
};
