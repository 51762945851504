import { Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import styled from "styled-components";

export const HeaderWrapper = styled(motion.div, {
  shouldForwardProp: (propName) =>
    propName !== "isScrollingDown" && propName !== "isScrolled",
})(({ isScrollingDown, isScrolled }) => ({
  width: "100%",
  boxShadow: isScrollingDown ? "0 1px 5px 0px rgba(0, 0, 0, 0.5)" : "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  zIndex: 99999999,
  height: isScrolled ? "70px" : "100px",
  background: isScrolled ? "var(--bg-color)" : "transparent",
  padding: isScrolled ? "20px 100px" : "30px 100px",
  transition: "all 0.25s ease-out",

  "@media (max-width: 1000px)": {
    justifyContent: "flex-end",
    height: isScrolled ? "60px" : "70px",
    boxShadow: "none",
    padding: isScrolled ? "15px 50px" : "30px 50px",
  },
  "@media (max-width: 600px)": {
    padding: isScrolled ? "15px 30px" : "30px 30px",
  },
}));

// export const HeaderWrapper = styled(motion.div)`
//   width: 100%;
//   /* padding: 30px 100px; */
//   box-shadow: ${({ isScrollingDown }) =>
//     isScrollingDown ? "0 1px 5px 0px rgba(0, 0, 0, 0.5)" : "none"};
//   /* height: 99px; */
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   top: 0;
//   z-index: 9999999999999999999999999;

//   /*----New stuff---*/
//   height: ${(props) => (props.isScrolled ? "70px" : "85px")};
//   background: ${(props) =>
//     props.isScrolled ? "var(--bg-color)" : "transparent"};
//   padding: ${(props) => (props.isScrolled ? "20px 100px" : "30px 100px")};
//   transition: all 0.4s ease-in-out;
//   @media (max-width: 1000px) {
//     justify-content: space-between;
//     height: ${(props) => (props.isScrolled ? "60px" : "70px")};
//     /* height: 77px; */
//     box-shadow: none;
//     padding: ${(props) => (props.isScrolled ? "15px 50px" : "30px 50px")};
//     /* padding: 30px 50px; */
//   }
//   @media (max-width: 600px) {
//     padding: ${(props) => (props.isScrolled ? "15px 30px" : "30px 30px")};
//     /* padding: 30px 30px; */
//   }
// `;

// export const BurgerMenuComponent = styled.div``;

export const Logo = styled(motion.a)`
  @media (max-width: 1000px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
//OLD LOGO STYLES
// export const MyImage = styled(Image)`
//   background-color: transparent !important;
//   height: ${(props) => (props.isScrolled ? "45px" : "50px")};
//   width: ${(props) => (props.isScrolled ? "136px" : "151px")};
//   @media (max-width: 1000px) {
//     height: ${(props) => (props.isScrolled ? "38px" : "45px")};
//     width: ${(props) => (props.isScrolled ? "115px" : "136px")};
//   }
// `;

//NEW LOGO STYLES
export const MyImage = styled(Image)`
  background-color: transparent !important;
  height: ${(props) => (props.isScrolled ? "75px" : "80px")};
  width: ${(props) => (props.isScrolled ? "166px" : "181px")};
  @media (max-width: 1000px) {
    height: ${(props) => (props.isScrolled ? "68px" : "75px")};
    width: ${(props) => (props.isScrolled ? "145px" : "176px")};
  }
`;

export const HeaderMenuWrapper = styled.ul`
  display: flex;
  align-items: center;
  background-color: transparent !important;
  > * + * {
    margin-left: 70px;
  }
  background-color: inherit;
  li {
    a {
      all: unset;
      font-size: 16px;
      span {
        cursor: pointer;

        font-size: 12px;
        &:not(:first-of-type) {
          font-size: 20px;
        }
      }
    }
    &:first-of-type a span {
      font-size: 20px;
    }
  }
  @media (max-width: 1300px) {
    > * + * {
      margin-left: 40px;
    }
  }
`;

export const BurgerMenu = styled.ul`
  position: fixed;
  overflow-y: hidden;
  top: 0px;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 100%;
  height: 100%;
  background-color: var(--bg-secondary-color);
  /* > * + * {
    margin-top: 60px;
  } */
  @keyframes drawer-open {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }
  @keyframes drawer-close {
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
  animation-name: ${({ isOpen }) => (isOpen ? "drawer-open" : "drawer-close")};
  animation-duration: 300ms;
  @media (max-width: 1000px) {
  }
  @media (max-width: 600px) {
    /* width: 230px;
    @keyframes drawer-open {
      from {
        right: -230px;
      }
    } */
  }
`;

export const BurgerMenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  > * + * {
    margin-top: 50px;
  }
  @media (max-width: 600px) {
    margin-top: 120px;
    > * + * {
      margin-top: 35px;
    }
  }
`;

export const BurgerMenuLogo = styled(motion.a)`
  @media (max-width: 1000px) {
    position: absolute;
    top: 12.5px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const BurgerMenuItem = styled.li`
  cursor: pointer;
  :first-child {
    /* margin-top: 90px; */
  }
  a {
    all: unset;
    display: flex;
    width: 100%;
    align-items: center;
    span:not(:first-child) {
      margin-left: 10px;
      /* @media (max-width: 800px) {
        font-size: 16px !important;
      } */
    }
  }
`;

export const SocialsWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  right: 50%;
  transform: translateX(50%);
`;

export const StyledHeaderLanguages = styled.div`
  position: absolute;
  top: 10;
  right: 50px;
  @media (max-width: 1300px) {
    right: 25px;
  }
`;
