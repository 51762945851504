import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import homeBg1 from "../../../images/Backgrounds/homeBgBBB.webp";
import homeBg2 from "../../../images/Backgrounds/homeBg2AA.webp";
import {
  HomeButton,
  HomePageSliderImage,
  HomePageStyledIntro,
  HomePageStyledText,
  IntroHeadingTitle,
  SliderSectionWrapper,
} from "./HomeSlider.styled";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const CustomCarousel = () => {
  const [t] = useTranslation();

  return (
    <Carousel
      autoPlay
      interval="3500"
      infiniteLoop
      //   width="100%"
      showIndicators="false"
      showStatus="false"
      emulateTouch
      showThumbs={false}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={20}
      className="prevent-select"
    >
      <SliderSectionWrapper className="SliderSectionWrapper">
        <HomePageSliderImage
          className="HomePageSliderImage"
          src={homeBg1}
          alt="Slide 1"
          style={{ opacity: "0.35" }}
        />
        <HomePageStyledIntro className="HomePageStyledIntro" id="first-slide">
          <IntroHeadingTitle className="IntroHeadingTitle">
            {parse(t("TK_TTL_HOME_SLIDER_1"))}
          </IntroHeadingTitle>
        </HomePageStyledIntro>
      </SliderSectionWrapper>

      <SliderSectionWrapper className="SliderSectionWrapper">
        <HomePageSliderImage
          src={homeBg2}
          alt="Slide 2"
          style={{ opacity: "0.4" }}
        />
        <HomePageStyledIntro className="HomePageStyledIntro">
          <IntroHeadingTitle className="IntroHeadingTitle">
            {parse(t("TK_TTL_HOME_SLIDER_2"))}
          </IntroHeadingTitle>
          <HomePageStyledText className="HomePageStyledText">
            {t("TK_DESC_HOME_SLIDER2")}
          </HomePageStyledText>
          <Link to="/aqua-page">
            <HomeButton colorScheme="purple">
              {t("TK_BTN_HOME_SLIDER2")}
            </HomeButton>
          </Link>
        </HomePageStyledIntro>
      </SliderSectionWrapper>
    </Carousel>
  );
};

export default CustomCarousel;
