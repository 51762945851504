import React from "react";
import { IconLink, SocialsWrapper } from "./Socials.styled";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

const Socials = () => {
  return (
    <SocialsWrapper>
      <IconLink href="https://www.linkedin.com/in/elixis-swiss-gmbh-95a873291/">
        <BsLinkedin />
      </IconLink>
      <IconLink href="https://www.facebook.com/profile.php?id=61551540654936&mibextid=2JQ9oc">
        <BsFacebook />
      </IconLink>
      <IconLink href="https://www.instagram.com/elixisswiss/?igshid=MWZjMTM2ODFkZg%3D%3D">
        <BsInstagram />
      </IconLink>
    </SocialsWrapper>
  );
};

export default Socials;
