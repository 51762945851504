import React from "react";
import {
  ColumnsWrapper,
  FooterWrapper,
  CreditsWrapper,
  DevelopedBy,
  Rights,
  ColumnsChildWrapper,
  TitleHeading,
  MyCustomAccordion,
} from "./Footer.styled";
import parse from "html-react-parser";
import Socials from "../socials/Socials";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomFooter = () => {
  const isSmallerThan1000 = useMediaQuery("(max-width: 1000px)");
  const [t] = useTranslation();

  return (
    <FooterWrapper className="FooterWrapper">
      <ColumnsWrapper className="DesktopFooterWrapper">
        <ColumnsChildWrapper>
          <TitleHeading as="h1">{t("TK_FOOTERTITLE_CONTACT")}</TitleHeading>
          {parse(
            "<p><a href='mailto:info@elixisswiss.ch'>info@elixisswiss.ch</a></p>"
          )}
          {parse("<p>Gantrischstrasse 39, 3600 Thun</p>")}
          {parse("<p><a href='tel: +41 79 643 64 47'>+41 79 643 64 47</a></p>")}
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">{t("TK_FOOTERTITLE_LOGO")}</TitleHeading>
          <p>
            <Link to="aqua-page">{t("TK_FOOTERTITLE_AQUAPRES")}</Link>
          </p>
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">{t("TK_FOOTERTITLE_POLICIES")}</TitleHeading>
          <p>
            <Link to="privacy-policy">{t("TK_FOOTERSUBTITLE_PRIVACY")}</Link>
          </p>
          <p>
            <Link to="about-us">{t("TK_FOOTERSUBTITLE_ABOUTUS")}</Link>
          </p>
          <p>
            <Link to="contact-us">{t("TK_FOOTERSUBTITLE_CONTACT")}</Link>
          </p>
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">{t("TK_FOOTERTITLE_CONNECT")}</TitleHeading>
          <Socials />
        </ColumnsChildWrapper>
      </ColumnsWrapper>

      {isSmallerThan1000 && (
        <MyCustomAccordion
          defaultIndex={[3]}
          allowMultiple
          className="MobileFooterWrapper"
        >
          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">
                    {t("TK_FOOTERTITLE_CONTACT")}
                  </TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {parse(
                  "<p><a href='mailto:info@elixisswiss.ch'>info@elixisswiss.ch</a></p>"
                )}
                {parse("<p>Gantrischstrasse 39, 3600 Thun</p>")}
                {parse(
                  "<p><a href='tel: +41 79 643 64 47'>+41 79 643 64 47</a></p>"
                )}
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">
                    {t("TK_FOOTERTITLE_LOGO")}
                  </TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <p>
                  <Link to="aqua-page">{t("TK_FOOTERTITLE_AQUAPRES")}</Link>
                </p>
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">
                    {t("TK_FOOTERTITLE_POLICIES")}
                  </TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <p>
                  <Link to="privacy-policy">
                    {t("TK_FOOTERSUBTITLE_PRIVACY")}
                  </Link>
                </p>
                <p>
                  <Link to="about-us">{t("TK_FOOTERSUBTITLE_ABOUTUS")}</Link>
                </p>
                <p>
                  <Link to="contact-us">{t("TK_FOOTERSUBTITLE_CONTACT")}</Link>
                </p>
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">
                    {t("TK_FOOTERTITLE_CONNECT")}
                  </TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Socials />
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>
        </MyCustomAccordion>
      )}

      <CreditsWrapper>
        <Rights>{t("TK_FOOTER_CREDITS")}</Rights>
        <DevelopedBy href="https://vasilht.netlify.app/">
          {t("TK_FOOTER_DESIGNEDBY")}
        </DevelopedBy>
      </CreditsWrapper>
    </FooterWrapper>
  );
};

export default CustomFooter;

//borderColor={"#6c6c6c"} ova odi na sekoj AccordionItem
