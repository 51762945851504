import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiGlobe } from "react-icons/ci";
import { languages } from "../header/utils/languages";
import {
  StyledOption,
  StyledSelect,
  StyledSelectWrapper,
} from "./SelectLanguage.styled";
import { useMediaQuery } from "../../utils/useMediaQuery";

export const SelectLanguage = () => {
  const [t, i18] = useTranslation();
  const [value, setValue] = useState(i18.resolvedLanguage);
  const handleChange = (event) => {
    setValue(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  const isSmallerThan1000 = useMediaQuery("(max-width: 1000px)");

  return (
    <StyledSelectWrapper>
      <StyledSelect
        value={value}
        onChange={handleChange}
        // placeholder="Controlled select"
        icon={<CiGlobe />}
        className="SelectLanguage-Select"
        variant={isSmallerThan1000 ? "outline" : "unstyled"}
      >
        {languages.map(({ code, name, country_code, flag, nameKey }) => (
          <StyledOption
            key={country_code}
            value={code}
            disabled={flag}
            nameKey={nameKey}
          >
            {t(nameKey)}
          </StyledOption>
        ))}
      </StyledSelect>
    </StyledSelectWrapper>
  );
};
