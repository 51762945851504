import { Formik, Form } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { useToast } from "@chakra-ui/toast";
import { MyCheckbox } from "./common/MyCheckbox";
import { MyInput } from "./common/MyInput";
import { MyTextarea } from "./common/MyTextArea";
import {
  MiniHeaderWrapper,
  MyContactButton,
  MyInputsWrapper,
  MyText,
  SuccessToast,
} from "./contactForm.styled";
import { useRef } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
  const toast = useToast();
  const toastIdRef = useRef();
  const [t] = useTranslation();

  const close = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  const addToast = (status) => {
    toastIdRef.current = toast({
      position: "bottom",
      duration: 8000,
      isClosable: true,
      render: () => (
        <SuccessToast status={status}>
          <MiniHeaderWrapper className="MiniHeaderWrapper">
            <IoMdCheckmarkCircleOutline />
            <h1>
              {status === "success"
                ? t("TK_TTL_TOAST_SUCCESS")
                : t("TK_TTL_TOAST_FAIL")}
            </h1>
          </MiniHeaderWrapper>
          <p>
            {status === "success"
              ? t("TK_DESC_TOAST_SUCCESS")
              : t("TK_DESC_TOAST_FAIL")}
          </p>
          <button onClick={() => close()}>x</button>
        </SuccessToast>
      ),
    });
  };

  const serviceID = process.env.REACT_APP_CONTACT_SERVICE_ID;
  const templateID = process.env.REACT_APP_CONTACT_TEMPLATE_ID;
  const publicKeyID = process.env.REACT_APP_CONTACT_PUBLIC_KEY_ID;

  return (
    <>
      <Formik
        initialValues={{
          firstAndLastName: "",
          email: "",
          message: "Hello ",
          acceptedTerms: false, // added for checkbox
        }}
        validationSchema={Yup.object({
          firstAndLastName: Yup.string()
            .max(30, t("TK_VALIDATION_NAME_MAX"))
            .required(t("TK_VALIDATION_NAME_REQ")),
          email: Yup.string()
            .email(t("TK_VALIDATION_EMAIL_INVALID"))
            .required(t("TK_VALIDATION_EMAIL_REQ")),
          message: Yup.string()
            .min(2, t("TK_VALIDATION_MESSAGE_MIN"))
            .max(200, t("TK_VALIDATION_MESSAGE_MAX"))
            .required(t("TK_VALIDATION_MESSAGE_REQ")),
          acceptedTerms: Yup.boolean()
            .required(t("TK_VALIDATION_TERMS_REQ"))
            .oneOf([true], t("TK_VALIDATION_TERMS_REQ")),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await new Promise((r) => setTimeout(r, 2000));
          setSubmitting(false);

          emailjs.send(serviceID, templateID, values, publicKeyID).then(
            function (response) {
              // console.log("SUCCESS!", response.status, response.text);
              if (response) {
                addToast("success");
              }
            },
            function (error) {
              // console.log("FAILED...", error);
              if (error) {
                addToast("error");
              }
            }
          );
          resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <MyInputsWrapper className="MyInputsWrapper">
              <MyInput
                label={t("TK_LABEL_FIRST_AND_LAST_NAME")}
                name="firstAndLastName"
                type="text"
                id="my-input"
                //   children={
                //     <IoMdContact style={{ width: "30px", height: "30px" }} />
                //   }
              />
              <MyInput
                label={t("TK_LABEL_EMAIL_ADDRESS")}
                name="email"
                type="email"
                id="my-input"
                //   children={
                //     <FaPaperPlane style={{ width: "25px", height: "25px" }} />
                //   }
              />
              <MyTextarea
                label={t("TK_LABEL_MESSAGE")}
                name="message"
                type="text"
                size="md"
                resize="none"
              />
              <MyText>
                {t("TK_PARAGRAPH_PRIVACY_POLICY")}
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "underline", fontWeight: "500" }}
                >
                  {t("TK_FOOTERSUBTITLE_PRIVACY")}
                </Link>
              </MyText>
              <MyCheckbox
                value="Checkbox"
                name="acceptedTerms"
                type="checkbox"
                style={{
                  height: "18px",
                  width: "18px",
                }}
              >
                {t("TK_LABEL_ACCEPTED_TERMS")}
              </MyCheckbox>
              <MyContactButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? t("TK_SUBMITTING") : t("TK_SUBMIT")}
              </MyContactButton>
            </MyInputsWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
