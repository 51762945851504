import { Link } from "react-router-dom";
import { StyledHeading } from "../../../../common/styles/Common.styled";
import {
  AquaContentWrapper,
  AquaCustomButton,
  AquaProductsWrapper,
  AquaStyledText,
} from "./AquaProducts.styled";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

export const AquaProducts = () => {
  const [t] = useTranslation();

  return (
    <AquaProductsWrapper className="AquaProductsWrapper" id="Products">
      <AquaContentWrapper className="AquaContentWrapper">
        <StyledHeading>{t("TK_TTL_HOME_AQUAPAGE")}</StyledHeading>
        <AquaStyledText style={{ backgroundColor: "transparent" }}>
          {parse(t("TK_DESC_HOME_AQUAPAGE_1"))}
          {parse(t("TK_DESC_HOME_AQUAPAGE_2"))}
          {parse(t("TK_DESC_HOME_AQUAPAGE_3"))}
        </AquaStyledText>
        <Link to="/aqua-page">
          <AquaCustomButton style={{ backgroundColor: "transparent" }}>
            {t("TK_BTN_HOME_AQUAPAGE")}
          </AquaCustomButton>
        </Link>
      </AquaContentWrapper>
    </AquaProductsWrapper>
  );
};
