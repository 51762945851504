import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import {
  ChildMainWrapper,
  MainWrapper,
  StyledAiOutlineArrowLeft,
  StyledHeading,
  StyledText,
  TextHeading,
} from "../common/styles/Common.styled";
import parse from "html-react-parser";
// import { useScrollToTop } from "../components/hooks/useScrollToTop";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import { useTranslation } from "react-i18next";

export const PrivacyPolicyPage = () => {
  // useScrollToTop("privacy-policy", -130);
  const [t] = useTranslation();

  useEffect(() => {
    document.title = "Elixis";
  });

  const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <MainWrapper
      className="MainWrapper"
      id="privacy-policy"
      style={{
        top: `${isSmallerThan1000 ? "80px" : "100px"}`,
        marginBottom: `${isSmallerThan1000 ? "80px" : "100px"}`,
      }}
      isOpen={showSidebar}
    >
      <ChildMainWrapper>
        <StyledHeading>
          <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
          {t("TK_TTL_PRIVACYP")}
        </StyledHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACYP_INTRO"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_1")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_1"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_2")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_2"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_3")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_3"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_4")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_4"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_5")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_5"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_6")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_6"))}</StyledText>
        <TextHeading as="h1">{t("TK_TTL_PRIVACY_7")}</TextHeading>
        <StyledText>{parse(t("TK_DESC_PRIVACY_7"))}</StyledText>
        <StyledText>{parse(t("TK_DESC_PRIVACYP_CONCL"))}</StyledText>
      </ChildMainWrapper>
    </MainWrapper>
  );
};
