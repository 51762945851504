// import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { MainWrapper } from "../common/styles/Common.styled";
// import parse from "html-react-parser";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import {
  AquaBgWrapper,
  AquaChildMainWrapper,
  AquaIntroContentWrapper,
  AquaIntroImage,
  AquaPageContentWrapper,
  AquaPageStyledText,
  AquaPageStyledTitle,
  BottlesSection,
  BottlesSectionWrapper,
  BoxesText,
  BoxesTitle,
  HorizLine,
  HorizLine2,
  IceContentWrapper,
  IceExtraImage,
  IceImage,
  IceMainWrapper,
  IceText,
  IceTitle,
  IceWrapper,
  IntroContentWrapper,
  IntroWrapper,
  VerticalLine,
} from "../components/aquaPage/AquaPage.styled";
import ImageWithHover from "../components/imageWithHover/ImageWithHover";
import boxThree from "../images/AquaPage/boxThreeNew.webp";
import boxThreeWhite from "../images/AquaPage/boxThreeWhiteNew.webp";
import boxThreeBlack from "../images/AquaPage/boxThreeBlackNew.webp";
import boxSix from "../images/AquaPage/boxSixNew.webp";
import boxSixWhite from "../images/AquaPage/boxSixWhiteNew.webp";
import boxSixBlack from "../images/AquaPage/boxSixBlackNew.webp";
import iceCubes from "../images/AquaPage/iceCubesNew.webp";
import aquaIntroBg from "../images/AquaPage/aquaIntroBg.webp";
import iceBlocksCubes2 from "../images/AquaPage/iceBlocksCubes2.webp";
import introBg from "../images/AquaPage/introBg2.webp";
import { useTranslation } from "react-i18next";

export const AquaPage = () => {
  const [t] = useTranslation();

  useEffect(() => {
    document.title = "Elixis";
  });

  //   const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <>
      <IntroWrapper className="IntroWrapper">
        <AquaBgWrapper
          className="AquaBgWrapper"
          imgSrc={introBg}
          opacity="0.5"
        />
        <IntroContentWrapper className="IntroContentWrapper">
          <AquaPageStyledTitle>{t("TK_TTL_AQUAPAGE")}</AquaPageStyledTitle>
          <AquaPageStyledText className="AquaPageStyledText">
            {t("TK_DESC_AQUAPAGE_0")}
          </AquaPageStyledText>
        </IntroContentWrapper>
      </IntroWrapper>

      <MainWrapper className="MainWrapper" id="aqua-page" isOpen={showSidebar}>
        <AquaChildMainWrapper>
          <AquaPageContentWrapper>
            <AquaIntroContentWrapper>
              <AquaIntroImage src={aquaIntroBg} />
              <AquaPageStyledText>
                {t("TK_DESC_AQUAPAGE_1")}
                <br />
                <br />
                {t("TK_DESC_AQUAPAGE_2")}
              </AquaPageStyledText>
            </AquaIntroContentWrapper>

            <BottlesSectionWrapper className="BottlesSectionWrapper">
              <BottlesSection id="BottlesSection-1">
                <BoxesTitle>{t("TK_SUBTTL_AQUAPAGE")}</BoxesTitle>
                <ImageWithHover src={boxThree} hoverSrc={boxThreeWhite} />
                <BoxesText>{t("TK_DESC_AQUAPAGE_3")}</BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-2">
                <BoxesTitle>{t("TK_SUBTTL_AQUAPAGE1")}</BoxesTitle>
                <ImageWithHover src={boxThree} hoverSrc={boxThreeBlack} />
                <BoxesText>{t("TK_DESC_AQUAPAGE_4")}</BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-3">
                <BoxesTitle>{t("TK_SUBTTL_AQUAPAGE2")}</BoxesTitle>
                <ImageWithHover src={boxSix} hoverSrc={boxSixWhite} />
                <BoxesText>{t("TK_DESC_AQUAPAGE_5")}</BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-4">
                <BoxesTitle>{t("TK_SUBTTL_AQUAPAGE3")}</BoxesTitle>
                <ImageWithHover src={boxSix} hoverSrc={boxSixBlack} />
                <BoxesText>{t("TK_DESC_AQUAPAGE_6")}</BoxesText>
              </BottlesSection>
              <VerticalLine />
              <HorizLine />
              <HorizLine2 />
            </BottlesSectionWrapper>

            <IceMainWrapper className="IceMainWrapper">
              <IceTitle>{t("TK_TTK_STEELICE")}</IceTitle>
              {isSmallerThan1000 ? (
                <IceWrapper className="IceWrapper">
                  <IceExtraImage src={iceBlocksCubes2} />
                  <IceContentWrapper>
                    <IceText>
                      <p>{t("TK_DESC_STEELICE_0")}</p>
                      <p>{t("TK_DESC_STEELICE_1")}</p>
                      <p>{t("TK_DESC_STEELICE_2")}</p>
                      <p>{t("TK_DESC_STEELICE_3")}</p>
                      <p>{t("TK_DESC_STEELICE_4")}</p>
                      <p>{t("TK_DESC_STEELICE_5")}</p>
                      <p>{t("TK_DESC_STEELICE_6")}</p>
                      <p>{t("TK_DESC_STEELICE_7")}</p>
                      <p>{t("TK_DESC_STEELICE_8")}</p>
                    </IceText>
                    <IceImage src={iceCubes} />
                  </IceContentWrapper>
                </IceWrapper>
              ) : (
                <IceWrapper className="IceWrapper">
                  <IceContentWrapper>
                    <IceExtraImage src={iceBlocksCubes2} />
                    <IceImage src={iceCubes} />
                  </IceContentWrapper>

                  <IceText>
                    <p>{t("TK_DESC_STEELICE_0")}</p>
                    <p>{t("TK_DESC_STEELICE_1")}</p>
                    <p>{t("TK_DESC_STEELICE_2")}</p>
                    <p>{t("TK_DESC_STEELICE_3")}</p>
                    <p>{t("TK_DESC_STEELICE_4")}</p>
                    <p>{t("TK_DESC_STEELICE_5")}</p>
                    <p>{t("TK_DESC_STEELICE_6")}</p>
                    <p>{t("TK_DESC_STEELICE_7")}</p>
                    <p>{t("TK_DESC_STEELICE_8")}</p>
                  </IceText>
                </IceWrapper>
              )}
            </IceMainWrapper>
          </AquaPageContentWrapper>
        </AquaChildMainWrapper>
      </MainWrapper>
    </>
  );
};
