export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "us",
    flag: false,
    nameKey: "TK_LNG_ENG",
  },
  {
    code: "it",
    name: "Italian",
    country_code: "it",
    flag: false,
    nameKey: "TK_LNG_ITALIAN",
  },
  {
    code: "de",
    name: "German",
    country_code: "de",
    flag: false,
    nameKey: "TK_LNG_GER",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
    flag: false,
    nameKey: "TK_LNG_FR",
  },
];
