import "./App.css";
import AppLayout from "./layout/AppLayout";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { AnimatedRoutes } from "./AnimatedRoutes";
import ScrollToTop from "./utils/scrollToTop";

const TRACKING_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID; // MY_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <BrowserRouter>
      <AppLayout>
        <ScrollToTop />
        <AnimatedRoutes />
      </AppLayout>
    </BrowserRouter>
  );
}

export default App;
